<template>
  <div class="content flex-box vertical">
    <div class="flex-grow flex-box vertical">
      <div class="data-title">
        <div class="rec-title-text">共{{ pagination.total }}条记录</div>
        <a-form-model class="query-form" layout="inline">
          <a-form-model-item label="主管">
            <select-book type="user" v-model="form.tangkou_director" @change="updateList"></select-book>
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.goods_name" placeholder="输入商品名称查询" allowClear></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.subscribe_num" placeholder="输入申购单编号查询" allowClear></a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px;"
              placeholder="选择堂口查询"
              @change="updateList"
              :options="tkOptions"
              v-model="form.tangkou_id"
              allowClear
              show-search
              option-filter-prop="children"
            >
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-range-picker
              v-model="form.subscribe_date"
              :placeholder="['申购开始日期', '申购截止日期']"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="updateList"
              class="range-picker"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.notes" placeholder="输入备注查询" allowClear></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-space class="title-btn">
          <a-button @click="getList">查询</a-button>
        </a-space>
      </div>
      <div class="data-tabs">
        <a-tabs :active-key="active" @change="changeTab">
          <a-tab-pane :key="tab.value" :tab="tab.label" v-for="tab in tabList"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="data-area">
        <a-table
          ref="dataTable"
          :columns="columns"
          row-key="id"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ y: h }"
          @change="handleTableChange"
        >
          <template v-slot:action="text, record, index">
            <div class="row-btn" @mousedown.stop @mouseup.stop>
              <a class="txt-btn" @click.stop="showDetail(record, index)" v-action:query>查看</a>
              <a class="txt-btn" @click.stop="editRow(record, index)" v-action:update>编辑</a>
              <a class="txt-btn danger" @click.stop="delRow(record, index)" v-action:delete>删除</a>
              <a class="txt-btn" @click.stop="warehousing(record, index)">入库</a>
            </div>
          </template>
          <!-- <a-table
            slot="expandedRowRender"
            slot-scope="text"
            row-key="id"
            :columns="innerColumns"
            :data-source="text.goods"
            :pagination="false"
          >
          </a-table> -->
        </a-table>
      </div>
    </div>
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      title="编辑"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
    >
      <a-form-model class="item-form" ref="editForm" :model="single" :rules="rules" layout="vertical" labelAlign="left">
        <a-form-model-item label="堂口名称" prop="tangkou_id">
          <a-select
            placeholder="选择堂口"
            :options="tkOptions"
            v-model="single.tangkou_id"
            allowClear
            show-search
            option-filter-prop="children"
            @change="tangkouChange($event, single)"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="部门主管" prop="tangkou_director">
          <a-select placeholder="选择部门主管" v-model="single.tangkou_director" allowClear>
            <a-select-option :value="director" v-for="director in directorList" :key="director">
              <open-data type="userName" :openid="director" />
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="申购日期" prop="subscribe_date">
          <a-date-picker
            v-model="single.subscribe_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            allowClear
            style="width: 100%;"
          />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
            v-model="single.notes"
            placeholder="填写备注"
            :autoSize="{ minRows: 4, maxRows: 6 }"
            :maxLength="200"
            allowClear
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="申购附件">
          <form-upload
            v-model="single.annex"
            list-type="text"
            :limit="9"
            :size="50 * 1024 * 1024 * 1"
            customAccept=".xls,.xlsx"
            uploadTip="仅支持.xls,.xlsx后缀的EXCEL文件"
            folder-prefix="purchase/"
          ></form-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <OrderModal
      v-model="infoVisible"
      :type="1"
      :form="infoForm"
      :info="info"
    ></OrderModal>
  </div>
</template>

<script>
import { getTemple } from "../../common/js/storage";
import {
  clone,
  dataConvertOptions,
  getQuery,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../common/js/tool";
import OrderModal from "./components/OrderModal.vue";

export default {
  name: "PurchaseListNew",
  components: { OrderModal },
  props: {
    active: Number,
    tabList: { type: Array, default: () => [] },
  },
  data() {
    return {
      url: "/admin/asset-subscribe",
      format: "YYYY-MM-DD",
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        { title: "序号", dataIndex: "rowIndex", key: "index", width: 80 },
        {
          title: "申购日期",
          dataIndex: "subscribe_date",
          customRender: (text) => {
            return timestampConvertString(text, "yyyy-MM-dd") || "-";
          },
        },
        { title: "申购编号", dataIndex: "subscribe_num" },
        {
          title: "堂口-主管",
          customRender: (text) => {
            let tangkou_name = text.tangkou?.name || text.tangkou_name;
            let tangkou_director = text.tangkou_director;
            if (!tangkou_name && !tangkou_director) {
              return "-";
            }
            return (
              <div style="width: max-content;">
                <span>{tangkou_name}-</span>
                <open-data type="userName" openid={tangkou_director} />
              </div>
            );
          },
        },
        { title: "操作", key: "action", width: 180, scopedSlots: { customRender: "action" } },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        tangkou_director: 2,
        goods_name: 2,
        subscribe_num: 2,
        tangkou_id: 2,
        subscribe_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&subscribe_start_date=${stringConvertTimestamp(
              value[0]
            )}&subscribe_end_date=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        notes: 2,
      },
      tkOptions: [],
      directorList: [],
      visible: false,
      single: {},
      rules: {
        tangkou_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        tangkou_director: [{ required: true, message: "请选择部门主管", trigger: "change" }],
        subscribe_date: [{ required: true, message: "请选择申购日期", trigger: "change" }],
      },
      innerColumns: [
        { title: "商品名称", dataIndex: "goods_name" },
        { title: "商品单位", dataIndex: "goods_unit_name" },
        { title: "申购数量", dataIndex: "num" },
      ],
      /* 申购单详情 */
      infoVisible: false,
      infoForm: {
        category: 1,
        warehouse_date: new Date().pattern("yyyy-MM-dd"),
      },
      info: null,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  created() {
    this.getTk();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    changeTab(val) {
      this.$emit("update:active", val);
    },
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    // 堂口改变
    tangkouChange(val, form) {
      this.$set(form, "tangkou_director", undefined);
      if (val) {
        let target = this.tkOptions.find((item) => item.value === val);
        this.directorList = target.admin_user_ids?.split(",") || [];
      } else {
        this.directorList = [];
      }
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    dealList(list) {
      const { current, pageSize } = this.pagination;
      list.forEach((item, index) => {
        item.rowIndex = (current - 1) * pageSize + index + 1;
        // item.goods?.forEach((item, index) => (item.id = "goods" + ++index));
      });
      return list;
    },
    getList() {
      this.loading = true;
      let url = this.url + "/list";
      let { current, pageSize } = this.pagination;
      url += `?page=${current}&pageSize=${pageSize}&sort=-subscribe_date,-create_time`;
      let temple = getTemple();
      if (temple) {
        url += `&temple_id=${temple}`;
      }
      url += getQuery.call(this, this.searchKeyType);
      this.$axios({ url, noTempleFilter: true })
        .then((res) => {
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    showModal() {
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },
    editRow(form) {
      this.single = clone(form);
      if (form.subscribe_date) {
        this.single.subscribe_date = timestampConvertString(form.subscribe_date, "yyyy-MM-dd");
      }
      if (form.tangkou_id) {
        let target = this.tkOptions.find((item) => item.value == form.tangkou_id);
        this.directorList = target.admin_user_ids?.split(",") || [];
      }
      this.showModal();
    },
    confirm() {
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = clone(this.single);
          let params = {
            tangkou_id: form.tangkou_id,
            tangkou_director: form.tangkou_director,
            subscribe_date: form.subscribe_date ? stringConvertTimestamp(form.subscribe_date) : null,
            notes: form.notes,
            annex: form.annex,
            subscribe_num: form.subscribe_num,
          };
          this.$axios({
            url: `${this.url}/subscribe-update`,
            method: "POST",
            data: params,
          }).then(() => {
            this.$message.success("更新成功");
            this.getList();
            this.hideModal();
          });
        }
      });
    },
    showDetail(item) {
      this.$router.push({ name: "PurchaseDetail", params: { id: item.subscribe_num } });
    },
    delRow(item) {
      this.$confirm({
        title: "提示",
        content: `确定删除此申购单吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/subscribe-delete`,
            method: "POST",
            data: {
              subscribe_num: item.subscribe_num,
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        },
      });
    },
    resetFrom() {
      this.infoForm = {
        category: 1,
        warehouse_date: new Date().pattern("yyyy-MM-dd"),
      };
    },
    // 入库
    warehousing(item) {
      this.resetFrom();
      const { subscribe_num } = item;
      this.$axios({
        url: "/admin/asset-subscribe",
        method: "GET",
        params: {
          "filter[subscribe_num]": subscribe_num,
          "filter[fixed_id][is]": "null",
        },
      }).then((res) => {
        const data = res.data;
        if (data.length > 0) {
          this.info = {
            subscribe_num,
            list: data,
          };
          this.infoVisible = true;
        } else {
          this.$message.error("不存在该申购单号!");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
