<template>
  <div class="content flex-box vertical">
    <data-list
      ref="dataList"
      class="flex-grow"
      :url="url"
      query="&sort=-subscribe_date,-create_time&expand=tangkou,goods&filter[fixed_id][is]=null"
      entity-name="申购单"
      hide-show-detail
      hide-edit
      hide-delete
      show-row-num
      :rules="rules"
      :columns="columns"
      :search-key-type="searchKeyType"
      :before-edit="beforeEdit"
      :before-update="beforeUpdate"
      :action="action"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item label="主管">
          <select-book type="user" v-model="form.tangkou_director" @change="updateList"></select-book>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.subscribe_goods" placeholder="输入商品名称查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.subscribe_num" placeholder="输入申购单编号查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择堂口查询"
            @change="updateList"
            :options="tkOptions"
            v-model="form.tangkou_id"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker
            v-model="form.subscribe_date"
            :placeholder="['申购开始日期', '申购截止日期']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="updateList"
            class="range-picker"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.notes" placeholder="输入备注查询" allowClear></a-input>
        </a-form-model-item>
      </template>
      <template v-slot:panel>
        <div class="data-tabs">
          <a-tabs :active-key="active" @change="changeTab">
            <a-tab-pane :key="tab.value" :tab="tab.label" v-for="tab in tabList"></a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <template v-slot:edit="{ form }">
        <!-- <a-form-model-item label="申购日期" prop="subscribe_date">
        <a-date-picker
          v-model="form.subscribe_date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          allowClear
          style="width: 100%;"
        />
      </a-form-model-item> -->
        <a-form-model-item label="商品名称" prop="subscribe_goods">
          <a-auto-complete
            placeholder="输入商品名称"
            :data-source="goodsOptions"
            v-model="form.subscribe_goods"
            :open="open"
            @search="goodsSearch"
            @select="goodsSelect"
            @blur="blur"
            @focus="focus"
            v-if="!visible"
          >
            <a-input>
              <a-spin slot="suffix" size="small" v-show="fetching" />
            </a-input>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item label="申购数量" prop="num">
          <a-input-number
            v-model="form.num"
            placeholder="请输入数字"
            :min="1"
            :step="1"
            allowClear
            style="width: 100%;"
          ></a-input-number>
        </a-form-model-item>
        <!-- <a-form-model-item label="单价">
        <a-input-number
          v-model="form.unit_price"
          placeholder="请输入"
          :min="0"
          :step="0.01"
          allowClear
          style="width: 100%;"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="堂口名称" prop="tangkou_id">
        <a-select
          placeholder="选择堂口"
          :options="tkOptions"
          v-model="form.tangkou_id"
          allowClear
          show-search
          option-filter-prop="children"
          @change="tangkouChange($event, form)"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="部门主管" prop="tangkou_director">
        <a-select placeholder="选择部门主管" v-model="form.tangkou_director" allowClear>
          <a-select-option :value="director" v-for="director in directorList" :key="director">
            <open-data type="userName" :openid="director" />
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea
          v-model="form.notes"
          placeholder="填写出库备注"
          :autoSize="{ minRows: 2, maxRows: 6 }"
          :maxLength="200"
          allowClear
        ></a-textarea>
      </a-form-model-item> -->
      </template>
      <template v-slot:action="{ item, index }">
        <a class="txt-btn" @click.stop="editRow(item, index)" v-action:update v-if="item.status == 1">
          编辑
        </a>
        <a class="txt-btn" @click.stop="warehousing(item, index)" v-action:update v-if="item.status == 1">
          入库
        </a>
        <a class="txt-btn danger" @click.stop="delRow(item, index)" v-action:delete>
          删除
        </a>
      </template>
    </data-list>
    <!--    入库-->
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      title="商品入库"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
      @cancel="cancel"
    >
      <a-form-model
        class="item-form"
        ref="wForm"
        :model="formData"
        :rules="formRules"
        layout="vertical"
        label-align="left"
      >
        <a-form-model-item label="入库类别" prop="category">
          <a-select placeholder="选择入库类别" :options="warehousingOptions" v-model="formData.category"></a-select>
        </a-form-model-item>
        <a-form-model-item label="入库日期" prop="warehouse_date" extra="仅允许选择当前及之前的日期">
          <a-date-picker
            v-model="formData.warehouse_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            allowClear
            style="width: 100%;"
            :disabled-date="disabledDate"
          />
        </a-form-model-item>
        <a-form-model-item label="商品名称" prop="subscribe_goods">
          <a-auto-complete
            placeholder="输入商品名称查询"
            :data-source="goodsOptions"
            v-model="formData.subscribe_goods"
            :open="open"
            @search="goodsSearch"
            @select="goodsSelect"
            @blur="blur"
            @focus="focus"
            disabled
            v-if="visible"
          >
            <a-input>
              <a-spin slot="suffix" size="small" v-show="fetching" />
            </a-input>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item label="入库数量" prop="num">
          <a-input-number
            style="width: 100%"
            v-model="formData.num"
            placeholder="请输入入库数量"
            :min="1"
            :step="1"
            disabled
            allowClear
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="固定资产">
          <a-switch v-model="formData.is_fixed_assets" @change="assetsChange" />
          <a-select
            v-if="formData.is_fixed_assets == 1"
            placeholder="选择固定资产类型"
            :options="fixedAssetsOptions"
            v-model="formData.fixed_assets_type"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="商品单价（元）">
          <a-input-number
            v-model="formData.unit_price"
            placeholder="请输入"
            :min="0"
            :step="0.01"
            allowClear
            style="width: 100%;"
            @change="updateTotalAmount($event, formData)"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="合计金额（元）">
          <a-input-number
            v-model="formData.price"
            placeholder="请输入"
            :min="0"
            :step="0.01"
            allowClear
            style="width: 100%;"
            disabled
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="交货人">
          <select-book type="user" v-model="formData.delivered_by"></select-book>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
            v-model="formData.notes"
            placeholder="填写入库备注"
            :autoSize="{ minRows: 2, maxRows: 6 }"
            allowClear
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="附件">
          <form-upload
            v-model="formData.annex"
            listType="text"
            :size="50 * 1024 * 1024 * 1"
            customAccept=".excel,.word,.pdf"
            uploadTip="仅支持excel，word，pdf后缀的文件"
          ></form-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { warehousingOptions, fixedAssetsOptions } from "../../common/asset/type";
import {
  clone,
  dataConvertOptions,
  isEmpty,
  stringConvertTimestamp,
  timestampConvertString,
  debounce,
} from "../../common/js/tool";
import moment from "moment";

export default {
  name: "PurchaseList",
  props: {
    active: Number,
    tabList: { type: Array, default: () => [] },
  },
  data() {
    this.lastFetchId = 0;
    this.goodsSearch = debounce(this.goodsSearch, 300);
    return {
      url: "/admin/asset-subscribe",
      searchKeyType: {
        subscribe_goods: 1,
        subscribe_num: 1,
        subscribe_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&filter[${key}][gte]=${stringConvertTimestamp(
              value[0]
            )}&filter[${key}][lte]=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        notes: 1,
      },
      columns: [
        {
          title: "日期",
          dataIndex: "subscribe_date",
          customRender: (text) => {
            return timestampConvertString(text, "yyyy-MM-dd") || "-";
          },
        },
        { title: "申购编号", dataIndex: "subscribe_num" },
        {
          title: "堂口-主管",
          customRender: (text) => {
            let tangkou_name = text.tangkou?.name || text.tangkou_name;
            let tangkou_director = text.tangkou_director;
            if (!tangkou_name && !tangkou_director) {
              return "-";
            }
            return (
              <div style="width: max-content;">
                <span>{tangkou_name}-</span>
                <open-data type="userName" openid={tangkou_director} />
              </div>
            );
          },
        },
        { title: "商品名称", dataIndex: "subscribe_goods" },
        { title: "数量", dataIndex: "num" },
        { title: "单价（元）", dataIndex: "unit_price" },
        { title: "金额（元）", dataIndex: "price" },
      ],
      rules: {
        subscribe_date: [{ required: true, message: "请选择申购日期", trigger: "change" }],
        subscribe_goods: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        num: [{ required: true, message: "请输入申购数量", trigger: "blur" }],
        tangkou_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        tangkou_director: [{ required: true, message: "请选择部门主管", trigger: "change" }],
      },
      tkOptions: [],
      directorList: [],

      visible: false,
      formData: {},
      formRules: {
        category: [{ required: true, message: "请选择入库类别", trigger: "change" }],
        warehouse_date: [{ required: true, message: "请选择入库日期", trigger: "change" }],
        subscribe_goods: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        num: [{ required: true, message: "请输入入库数量", trigger: "blur" }],
      },
      warehousingOptions,
      fixedAssetsOptions,

      open: false,
      fetching: false,
      goodsOptions: [],
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    action() {
      return this.$route.meta.action;
    },
  },
  created() {
    this.getTk();
  },
  mounted() {
    setTimeout(() => {
      const myEvent = new Event("resize");
      window.dispatchEvent(myEvent);
    });
  },
  methods: {
    changeTab(val) {
      this.$emit("update:active", val);
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 更新合计金额
    updateTotalAmount(val, form) {
      if (!isEmpty(form.num) && !isEmpty(form.unit_price)) {
        this.$set(form, "price", form.num * form.unit_price);
      } else {
        this.$set(form, "price", 0);
      }
    },
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.dataList.updateList();
    },
    getList() {
      this.dataList.getList();
    },
    beforeEdit(form, fn) {
      if (form.goods_id) {
        form.subscribe_goods = form.goods?.name;
      }
      if (form.subscribe_date) {
        form.subscribe_date = timestampConvertString(form.subscribe_date, "yyyy-MM-dd");
      }
      if (form.tangkou_id) {
        let target = this.tkOptions.find((item) => item.value === form.tangkou_id);
        this.directorList = target.admin_user_ids?.split(",") || [];
      }
      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (res.subscribe_date) {
        res.subscribe_date = stringConvertTimestamp(res.subscribe_date);
      }
      fn(res);
    },
    // 堂口改变
    tangkouChange(val, form) {
      this.$set(form, "tangkou_director", undefined);
      if (val) {
        let target = this.tkOptions.find((item) => item.value === val);
        this.directorList = target.admin_user_ids?.split(",") || [];
      } else {
        this.directorList = [];
      }
    },
    // 入库
    warehousing(item) {
      this.formData = {
        id: item.id,
        category: 1,
        warehouse_date: new Date().pattern("yyyy-MM-dd"),
        subscribe_goods: item.subscribe_goods,
        num: item.num,
        is_fixed_assets: item.is_fixed_assets == 1,
        fixed_assets_type: item.fixed_assets_type,
        unit_price: item.unit_price,
        price: item.price,
      };
      this.visible = true;
    },
    confirm() {
      this.$refs.wForm.validate((flag) => {
        if (flag) {
          let params = clone(this.formData);
          if (params.warehouse_date) {
            params.warehouse_date = stringConvertTimestamp(params.warehouse_date);
          }
          if (params.delivered_by?.length > 0) {
            params.delivered_by = params.delivered_by[0];
          }
          params.is_fixed_assets = params.is_fixed_assets ? 1 : 0;
          this.$axios({
            url: "/admin/asset-subscribe/add-warehouse",
            method: "POST",
            data: params,
          }).then((res) => {
            if (res.error == 0) {
              this.$message.success("入库成功!");
              this.getList();
              this.visible = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    cancel() {},
    editRow(item) {
      this.dataList.editRow(item);
    },
    delRow(item) {
      this.dataList.delRow(item);
    },
    assetsChange(val) {
      if (val) {
        this.formData.fixed_assets_type = fixedAssetsOptions[0].value;
      } else {
        this.formData.fixed_assets_type = undefined;
      }
    },
    /* 查询商品名称 */
    goodsSearch(keyword) {
      if (isEmpty(keyword)) {
        this.goodsOptions = [];
      } else {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.goodsOptions = [];
        this.fetching = true;
        this.$axios({
          url: "/admin/asset-goods",
          method: "GET",
          params: {
            sort: "-id",
            expand: "goodsUnit",
            page: 1,
            pageSize: 100,
            "filter[name][like]": keyword,
          },
        })
          .then((res) => {
            if (fetchId !== this.lastFetchId) {
              // for fetch callback order
              return;
            }
            this.goodsOptions = res.data.map((item) => {
              return {
                ...item,
                value: item.name,
                text: item.name,
              };
            });
            this.open = true;
          })
          .finally(() => {
            this.fetching = false;
          });
      }
    },
    goodsSelect() {
      this.open = false;
    },
    focus() {
      if (this.goodsOptions?.length) {
        this.open = true;
      }
    },
    blur() {
      this.open = false;
    },
  },
};
</script>

<style lang="less">
.range-picker {
  text-align: left;
}
.data-tabs {
  text-align: center;
}
.ant-tabs-bar {
  margin-bottom: 0;
}
.data-area {
  margin-top: 0;
}
</style>
