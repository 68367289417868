<template>
  <PurchaseList :active.sync="active" :tabList="tabList" v-if="active == 1" />
  <PurchaseListNew :active.sync="active" :tabList="tabList" v-else-if="active == 2" />
</template>

<script>
import PurchaseList from "./PurchaseList.vue";
import PurchaseListNew from "./PurchaseListNew.vue";

export default {
  name: "PurchasePolymerize",
  components: {
    PurchaseList,
    PurchaseListNew,
  },
  data() {
    return {
      active: 1,
      tabList: [
        { label: "申购商品列表", value: 1 },
        { label: "申购单列表", value: 2 },
      ],
    };
  },
  computed: {
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    if (this.tab) {
      this.active = this.tab;
    }
  },
};
</script>

<style lang="less" scoped></style>
